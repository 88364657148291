import { ethers } from "ethers";
import { tokens, chains } from "./constants";


/**
 * Function to get the provider based on MetaMask availability.
 */
export function getProvider() {
  if (window.ethereum) {
    return new ethers.BrowserProvider(window.ethereum);
  } else {
    console.error("MetaMask is not installed!");
    return null;
  }
}

/**
 * Function to get the signer from the provider.
 */
export async function getSigner(provider) {
  try {
    const signer = await provider.getSigner();
    return signer;
  } catch (error) {
    console.error("Failed to get signer: ", error);
    throw error;
  }
}

// Function to handle token transfers based on the provided token and chain data
export async function handleTokenTransfer  (fromChainId, fromTokenSymbol, toAddress, amount, signer) {
  try {

    console.log("fromTokenSymbol ---- ",fromTokenSymbol);
    // Find the token information from the tokens object
    const tokenInfo = tokens[fromChainId]?.find(
      token => token.symbol === fromTokenSymbol
    );

    if (!tokenInfo) {
      throw new Error(`Token ${fromTokenSymbol} not found on chain ${fromChainId}`);
    }

    // Check if it's a native token or an ERC-20 token
    if (tokenInfo.address === null) {
      // Native Token Transfer (e.g., ETH, BNB)
      const amountInWei = ethers.parseEther(amount);
      console.log(`Initiating native token (${fromTokenSymbol}) transfer of ${amountInWei} wei to ${toAddress}...`);
      console.log("chainId....",fromChainId);
      const tx = await sendTransaction(signer,{
        to: toAddress,
        value: amountInWei,
        chainId: fromChainId,
      });
      // await tx.wait();
      console.log("Native token transfer successful. Transaction response:", tx);
      return tx; // Return the transaction response
    } else {
      // ERC-20 Token Transfer
      const erc20Abi = [
        "function transfer(address to, uint256 amount) public returns (bool)",
      ];

      const tokenContract = new ethers.Contract(tokenInfo.address, erc20Abi, signer);

      const amountInUnits = ethers.parseUnits(amount, 18); // Adjust decimals as needed

      console.log(`Initiating ERC-20 token (${fromTokenSymbol}) transfer of ${amountInUnits} units to ${toAddress}...`);

      const tx = await tokenContract.transfer(toAddress, amountInUnits);
      await tx.wait();
      console.log("ERC-20 token transfer successful. Transaction response:", tx);
      return tx; // Return the transaction response
    }
  } catch (error) {
    console.error(`Error during token transfer:`, error);
    throw error;
  }
};

/**
 * Function to create and send a transaction.
 * @param {object} signer - The signer object.
 * @param {transectionblock} tx - The transaction block.
 */
export async function sendTransaction(signer, tx) {
  try {
    console.log("sending transection ....... ", tx);


    if (tx.chainId === 997n || tx.chainId === 995n || tx.chainId === 601n || tx.chainId === "601" || tx.chainId === '997' || tx.chainId === '995' ) {
      // Remove chainId from tx
      console.log("Removing chainid from tx ");
      delete tx.chainId;
    }
    console.log("sending transection ....... ", tx);
    const transactionResponse = await signer.sendTransaction(tx);
    await transactionResponse.wait();

    return transactionResponse;
  } catch (error) {
    console.error("Transaction failed: ", error);
    throw error;
  }
}


