import axios from "axios";
import { CONFIG, tokens } from "../utils/constants";

const { API_KEY, BASE_URL, MPC_ADDRESS } = CONFIG;

// Consolidated headers for reuse
const defaultHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${API_KEY}`,
};

// Utility function for error logging
const logError = (error) => {
  if (error.response) {
    console.error("API Response Error:", error.response.data);
  } else {
    console.error("API Error:", error.message);
  }
};

// Utility function for chain ID validation
const convertChainId = (chainId) => {
  if (chainId <= Number.MAX_SAFE_INTEGER) {
    return Number(chainId);
  } else {
    throw new Error("Chain ID is too large to convert safely.");
  }
};

// Function to check server availability
export const checkServerAvailability = async () => {
  try {
    console.log("Connecting to checkServerAvailability API at");
    const { data } = await axios.get("health/ready");
    console.log("Server availability check response:", data);
    return data.status === "ok";
  } catch (error) {
    logError(error);
    return false;
  }
};

// Generate BTC Address
export const generateBtcAddress = async () => {
  try {
    console.log("Connecting to generateBtcAddress API at" );
    const { data } = await axios.post("btc/derive/account", {}, { headers: defaultHeaders });
    console.log("Generated BTC Address:", data.address);
    return data.address;
  } catch (error) {
    logError(error);
    throw error;
  }
};

// Generate EVM Address
export const generateEvmAddress = async (chainId) => {
  try {
    const chainIdInt = convertChainId(chainId);
    const requestBody = {
      hd_path: [44, 60, 0, 0, 0],
      chain_id: chainIdInt,
    };

    console.log("Request body for generateEvmAddress API:", requestBody);
    const { data } = await axios.post("derive/account", requestBody, { headers: defaultHeaders });
    console.log("Generated EVM Address:", data.address);
    return data.address;
  } catch (error) {
    logError(error);
    throw error;
  }
};

// Handle token transfers
export const targetChainApiCall = async (
  transactionHash,
  fromAddress,
  toAddress,
  amount,
  fromChainId,
  toChainId,
  fromTokenSymbol,
  fromContractAddress,
  toTokenSymbol
) => {
  try {
    const tokenInfo = tokens[toChainId]?.find(token => token.symbol === toTokenSymbol);

    if (!tokenInfo) {
      throw new Error(`Token ${fromTokenSymbol} not found on chain ${fromChainId}`);
    }

    const isNativeToken = tokenInfo.address === null;
    const targetEndpoint = isNativeToken ? "transaction" : "evm/transfer";
    const amountStr = typeof amount === "bigint" ? amount.toString() : amount;
    const requestBody = {
      from: fromAddress,
      to: toAddress,
      source_chain_id: fromChainId ,
      target_chain_id: toChainId || null,
      amount: amountStr,
      tx: transactionHash,
      ...(isNativeToken ? {} : { contract: tokenInfo.address }),
    };

    console.log(`Request body for ${isNativeToken ? "native" : "ERC-20"} transfer:`, requestBody);

    const { data } = await axios.post(`${targetEndpoint}`, requestBody, { headers: defaultHeaders });
    console.log("MPC API Response:", data);
    return data;
  } catch (error) {
    logError(error);
    throw error;
  }
};
