import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { chains, tokens } from "../utils/constants";
import { getProvider, getSigner } from "../utils/signingUtils";

const SelectField = ({ label, id, options, value, onChange, disabled }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <select
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
    >
      <option value="" disabled>
        Select {label}
      </option>
      {options.map((option) => (
        <option
          key={option.id || option.symbol}
          value={option.id || option.symbol}
        >
          {option.name || option.symbol}
        </option>
      ))}
    </select>
  </div>
);

const FormField = ({ label, id, value, onChange, disabled, placeholder }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      type="text"
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
    />
  </div>
);

const MintPage = ({ defaultAccount, network, chainId }) => {
  const [selectedChainId, setSelectedChainId] = useState('');
  const [selectedToken, setSelectedToken] = useState("");
  const [amount, setAmount] = useState("");
  const[address,setAddress] = useState('');
  const [mintingStatus, setMintingStatus] = useState("");
  const [isMinting, setIsMinting] = useState(false);

  useEffect(() => {
    if (chainId) {
      setSelectedChainId(`${chainId}`);
    }
  }, [network, chainId]);

  const handleMint = async () => {

    console.log('chainId..............',chainId);
    if (!selectedChainId || !selectedToken || !amount) {
      alert("Please select a chain, token, and enter an amount to mint.");
      return;
    }

    try {
      setIsMinting(true);
      setMintingStatus("Minting in progress...");

      const provider = getProvider();
      const signer = await getSigner(provider);

      const tokenAddress = tokens[selectedChainId].find(
        (token) => token.symbol === selectedToken
      ).address;

      if (!tokenAddress) {
        alert("Invalid token address.");
        setIsMinting(false);
        return;
      }

      // Load the token contract
      const tokenAbi = [
        // Add ABI for the mint function
        "function mint(address to, uint256 amount) public",
      ];
      const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

      // Execute mint function
      const mintTx = await tokenContract.mint(
        address,
        ethers.parseUnits(amount, 18)
      );
      await mintTx.wait();

      setMintingStatus("Minting successful!");
    } catch (error) {
      console.error("Error during minting:", error);
      setMintingStatus("Minting failed. Please try again.");
    } finally {
      setIsMinting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Mint Tokens</h2>

      <SelectField
        label="Chain"
        id="chain-select"
        options={chains}
        value={selectedChainId}
        onChange={(e) => setSelectedChainId(e.target.value)}
        disabled={true} // Disable the dropdown
      />

      <SelectField
        label="Token"
        id="token-select"
        options={tokens[selectedChainId] || []}
        value={selectedToken}
        onChange={(e) => setSelectedToken(e.target.value)}
      />

      <FormField
        label="Amount"
        id="mint-amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount to mint"
      />
      <FormField
        label="Address"
        id="mint-address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder="Enter address to mint"
      />

      <button
        onClick={handleMint}
        className={`p-4 mt-4 text-lg font-semibold text-white rounded-lg ${
          isMinting ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-800"
        }`}
        disabled={isMinting}
      >
        {isMinting ? "Minting..." : "Mint Tokens"}
      </button>

      {mintingStatus && <p className="mt-4">{mintingStatus}</p>}
    </div>
  );
};

export default MintPage;
